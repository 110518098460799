@import "./../../Base.main.scss";

footer {
  padding: 30px 30px;
  //   background-color: black;
  background-color: $navy;
  box-shadow: 0px -2px 2px -2px rgb(0 0 0 / 15%);
}

.footer-grid {
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-auto-flow: column;
    grid-auto-columns: 165px;
  }

  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  // grid-column-gap: 20px;
  // grid: 200px / auto auto;
  //   grid-auto-columns: 50%;

  .grid-item {
    padding: 20px 0;

    .left-link, .right-link {
      display: block;
      font-family: $font-1;
      color: $grey;
      font-size: 16px;
      margin-top: 25px;
      cursor: pointer;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .right-link {
      text-decoration: none;
      span {
        margin-left: 5px;
      }
    }

    h4 {
      font-family: $font-1;
      color: $grey;
      // font-weight: 900;
      border-bottom: 2px dotted $grey;
      margin-top: 0;
      font-size: 20px;
    }
  }
}
