@import "./../../../Base.main.scss";

.ReactModal__Overlay {
  opacity: 0;
  z-index: 30;
  background-color: rgba(141, 153, 174, 0.5) !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  border: none !important;
  border-radius: 0 !important;
  // box-shadow: 0 0 2px 0 $navy;
  color: $navy;
  font-family: $font-1;
  width: calc(100vw - 40px);
  max-width: 600px;
  box-sizing: border-box;

  //   max-height: 95%;
  //   transform: translate(-50%, -50%);

  //   max-width: 600px;
  //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  //   box-sizing: border-box;
  //   display: flex;
  //   flex-direction: column;
  //   padding: 24px;
  //   background-color: $vanilla;
  //   align-items: center;

  .modal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 15px 0;
  }
}
