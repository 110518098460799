@import "./../../Base.main.scss";

.multi-select-dropdown {
  //   margin: 20px auto;
  position: relative;
  //   max-width: 800px;
  width: 100%;
  font-family: $font-1;
  box-sizing: border-box;

  &:hover {
    display: block;
  }

  .multi-select-dropdown__selected {
    border: solid 2px $grey;
    font-size: 14px;
    padding: 20px;
    display: flex;
    width: 75%;
    box-sizing: border-box;
    background: white;
    justify-content: space-between;
    align-items: center;
    color: $navy;
    cursor: pointer;

    @media (max-width: 592px) {
      width: 100%;
    }

    .selected-flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .chip {
        background: $grey;
        color: white;
        margin-right: 5px;
        padding: 8px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .multi-select-dropdown__options {
    // display:none;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    width: 75%;
    list-style: none;
    padding-left: 0px;
    border: solid 2px $grey;
    background: white;
    padding: 0;
    margin: 0;
    z-index: 5;

    @media (max-width: 592px) {
      width: 100%;
    }
  }

  .multi-select-dropdown__option {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    cursor: pointer;
    color: $navy;

    &:hover {
      background-color: $vanilla;
    }

    .checkbox {
      margin-right: 6px;
      color: $navy;
    }
  }
}
