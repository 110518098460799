@import "./../../Base.main.scss";

.container{
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    font-family: $font-1;
    color: $navy;

    .breadcrumb {
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }
}