@import "./../../Base.main.scss";

.star {
  font-size: 50px;
  position: relative;
  display: inline-block;
  transform: rotate(45deg);
  cursor: pointer;

  @media (max-width: 592px) {
    font-size: 32px;
  }
}
.star-under {
  color: $grey;
  vertical-align: top;
}
.star-over {
  color: $navy;
  position: absolute;
  left: 0;
  top: 0;
  // width: 70%;
  overflow: hidden;
}

.number{
    font-size: 50px;
    margin-left: 20px;
    background-color: $grey;
    padding: 10px;
    color: $navy;
    box-sizing: border-box;
    @media (max-width: 592px) {
        font-size: 40px;
      }
}