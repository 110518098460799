@import "./../../../Base.main.scss";

.dropdown {
  // border: 2px solid $grey;
  max-width: 100px;
  // padding: 20px;
  // margin: 20px auto;

  // // box-shadow: 0 0 4px 0 $navy;
  box-sizing: border-box;
  width: 100%;
  font-family: $font-1;
  // height: 79px;
  margin: 10px;

  // position: relative;
  // margin: 10px;

  .selected {
    display: flex;
    z-index: 10;
    flex-grow: 1;
    -webkit-flex-grow: 1;
  }
}

.dropdown.home, .dropdown.search, .dropdown.search-sort, .dropdown.classes, .dropdown.burger {
  margin: 0;
  height: auto;
  width: auto;
  margin-left: 10px;
}

.dropdown.search-sort,
.dropdown.search,
.dropdown.trainer {
  width: 50%;
  max-width: 300px;
  margin: 0;
  height: auto;
  margin-left: 10px;
  position: relative;

  .dropdown-content {
    width: 100%;
    max-width: 300px;
  }
}
.dropdown-btn {
  cursor: pointer;
  background: $vanilla;
  display: flex;
  // align-items: center;

  justify-content: space-between;
  padding: 10px;
  background: $vanilla;
  border: 2px solid $grey;
  // border-radius: 5px;
  color: $navy;
  font-weight: 500;
  box-sizing: border-box;
}

.dropdown-btn.home {
  color: $vanilla;
  background: none;
  border: none;
  justify-content: flex-start;
  padding: 0;
  font-weight: 600;

  @media (max-width: 592px) {
    color: $navy;
  }

  div:first-of-type {
    margin-right: 5px;
  }
}

.dropdown-btn.burger {
  color: $vanilla;
  background: none;
  border: none;
  justify-content: flex-start;
  padding: 0;
  font-weight: 600;

  div:first-of-type {
    margin-right: 5px;
  }
}

.dropdown-btn.search, .dropdown-btn.classes {
  color: $navy;
  background: none;
  border: none;
  justify-content: flex-start;
  padding: 0;
  font-weight: 600;

  @media (max-width: 592px) {
    color: $navy;
  }

  div:first-of-type {
    margin-right: 5px;
  }
}

.dropdown-content {
  position: absolute;
  background: $vanilla;
  max-width: 100px;
  /* top: 110%; */
  // left: -2px;
  width: 100%;
  color: $navy;
  z-index: 11;
  border: 1px solid $navy;
  box-sizing: border-box;

  //  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);

  .item {
    padding: 10px;
    cursor: pointer;

    &:hover {
      background: $grey;
      color: $vanilla;
    }
  }
}
