@import "./../../Base.main.scss";

.tabs{
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    .tab{
        width: 50%;
        text-align: center;
        border-bottom: 1px solid $navy;
        font-size: 24px;
        padding: 5px;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
    }

    .tab.active{
        border-bottom: 3px solid $navy;
    }
}