@import "./../../Base.main.scss";

.inputs {
  display: flex;
  flex-direction: row;
  width: 100%;
  //   max-width: 1000px;
  padding: 20px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
  padding-top: 0;
  // margin-top: 20px;

  @media (max-width: 592px) {
    flex-direction: column;
  }

  .input-container {
    margin-left: 10px;
    width: 50%;
    box-sizing: border-box;
    max-width: 800px;

    @media (max-width: 592px) {
      width: 100%;
      margin-bottom: 0;
      margin-left: 0;
      border-bottom: 1px solid $navy;
    }

    label {
      color: $navy;
      font-size: 18px;
    }

    input {
      // padding: 0 15px;
      margin: auto;
      padding: 20px;
      width: 100%;
      border-radius: 0;
      border: 2px solid $grey;
      color: $navy;
      font-size: 16px;
      box-sizing: border-box;
      font-family: $font-1;
      background: white;

      @media (max-width: 592px) {
        padding: 10px;
      }

      &::placeholder {
        color: rgb(153, 153, 153);
        opacity: 1;
      }

      &:focus {
        outline: none !important;
        border: 2px solid $navy;
      }
    }
  }
}

.inputs.classes{
  justify-content: flex-start;
}

.filters {
  display: flex;
  flex-direction: row;
  width: 100%;
  //   max-width: 1000px;
  padding: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
  font-family: $font-1;

  // align-items: center;
  // justify-content: center;
  @media (max-width: 592px) {
    padding: 20px 20px 0 20px;
  }
  .filter-div {
    min-width: 50%;
    display: flex;
    color: $navy;

    &:first-of-type{
    margin-right: 5px;
    }
    @media (max-width: 592px) {
      color: $navy;
    }
  }
}


.sort {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: end;
  padding: 0 20px;
  box-sizing: border-box;

  @media (max-width: 592px) {
    justify-content: center;
  }
  .filter-desktop {
    @media (max-width: 592px) {
      display: none;
    }
  }
  .filter-mobile {
    width: 50%;
    @media (min-width: 592px) {
      display: none;
    }
  }
}

.flex {
  display: flex;
  flex-direction: row;
  width: 100%;

  .search {
    width: 100%;
    // max-width: 95%;
  }

  .filter {
    // width: 20%;
    min-width: 200px;
    max-width: 250px;
    padding: 20px;

    @media (max-width: 592px) {
      display: none;
    }
    // max-width: 250px;

    .filter-desktop {
      @media (max-width: 592px) {
        display: none;
      }
    }

    h2 {
      margin-top: 0;
    }

    .filter-title {
      font-family: $font-1;
      color: $navy;
    }

    .filter-group {
      border-top: 1px solid $navy;
      border-bottom: 1px solid $navy;
    }

    .accordion-title {
      font-family: $font-1;
      color: $navy;
      font-size: 18px;
    }

    .item {
      color: $navy;
      list-style-type: none;
      font-family: $font-1;
      cursor: pointer;
      margin: 5px 0;
      // min-height: 3px;
      box-sizing: border-box;
    }

    .count {
      background: $navy;
      color: $vanilla;
      padding: 0 8px;
      box-sizing: border-box;
    }
  }

  .trainer-item {
    background-color: none;
    font-family: $font-1;
    // color: $navy;
    width: 100%;

    .top {
      display: grid;
      grid-template-columns: repeat(4,minmax(0,1fr));
      // flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 5px;
      min-height: 34px;
 

      h2 {
        // color: $vanilla;
        font-size: 20px;
        margin: 0;

        @media (max-width: 592px) {
          font-size: 14px;
        }
      }

      box-sizing: border-box;

      @media (max-width: 592px) {
        // flex-direction: column;
        // align-items: flex-start;
        padding: 5px 10px;
      }



      .stars-mobile {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;

        .star {
          font-size: 14px;
          position: relative;
          display: inline-block;
          transform: rotate(45deg);
        }

        .rating {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    .content {
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @media (max-width: 592px) {
        padding: 10px;
        flex-direction: column;
      }
    }
  }
}

.drawer {
  h2 {
    // margin-top: 0;
    padding-left: 5px;
  }

  .filter-title {
    font-family: $font-1;
    color: $navy;
  }

  .filter-group {
    border-top: 1px solid $navy;
    border-bottom: 1px solid $navy;
  }

  .accordion-title {
    font-family: $font-1;
    color: $navy;
    font-size: 18px;
  }

  .item {
    color: $navy;
    list-style-type: none;
    font-family: $font-1;
    cursor: pointer;
    margin: 5px 0;
    // min-height: 3px;
    box-sizing: border-box;
  }

  .count {
    background: $navy;
    color: $vanilla;
    padding: 0 8px;
    box-sizing: border-box;
  }
}
