@import "./../../Base.main.scss";

.mobile-item {
  font-family: $font-1;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;

  a {
    text-decoration: none;
    color: $grey;
    transition: 0.3s all;

    &:hover {
      opacity: 0.6;
    }
  }
}

.contacts {
  display: flex;
  align-items: center;

  .icons {
    margin: 0 8px;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    color: $grey;

    &:first-of-type {
      margin-left: 0;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

.sidebar-black {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.caret-rotate {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}
.caret-rotate.open {
  transform: rotate(180deg);
}

.filters {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 0;
  box-sizing: border-box;
  font-family: $font-1;
  .filter-div {
    min-width: 50%;
    display: flex;
    color: $vanilla;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding-top: 0;

  .input-container {
    box-sizing: border-box;
    max-width: 800px;
    width: 100%;
    margin-top: 10px;

    label {
      color: $grey;
      font-size: 18px;
    }

    input {
      // padding: 0 15px;
      margin: auto;
      padding: 10px;
      width: 100%;
      border-radius: 0;
      border: 2px solid $grey;
      color: $navy;
      font-size: 16px;
      box-sizing: border-box;
      font-family: $font-1;
      background: $vanilla;

      &::placeholder {
        color: rgb(153, 153, 153);
        opacity: 1;
      }

      &:focus {
        outline: none !important;
        border: 2px solid $navy;
      }
    }
  }
}
.inputs.classes {
  justify-content: flex-start;
}
