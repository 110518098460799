@import "./../../Base.main.scss";

.filter {
  width: 350px;
}

.x {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 24px;
  color: $navy;
  cursor: pointer;
  font-weight: 600;
}

.mobile-filter {
  width: 100%;
  @media (min-width: 592px) {
    display: none;
  }

  button {
    box-sizing: border-box;
    // margin: 10px;
    padding: 10px;
    width: 100%;
    background-color: $navy;
    border: none;
    color: $vanilla;
    font-family: $font-1;
    text-transform: uppercase;
    font-weight: 800;
    box-sizing: border-box;
    height: 43px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      opacity: 0.7;
    }
  }
}
