@import "./../../../Base.main.scss";

.radiobutton {
  height: 38px;
  label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.4em;
    /* Accessible outline */
    /* Remove comment to use */
    /*
      &:focus-within {
          outline: .125em solid $primary-color;
      }
    */
    input {
      position: absolute;
      left: -9999px;
      &:checked + span {
        background-color: $navy;
        &:before {
          box-shadow: inset 0 0 0 0.4375em $vanilla;
        }
      }
      &:checked + span {
        color: $vanilla;
      }

    }
    span {
      display: flex;
      align-items: center;
      padding: 0.375em 0.75em 0.375em 0.375em;
      // border-radius: 99em; // or something higher...
      transition: 0.25s ease;
      &:first-of-type{
        margin-right: 20px;
      }
      &:hover {
        background-color: mix(#fff, $grey, 56%);
      }
      &:before {
        display: flex;
        flex-shrink: 0;
        content: "";
        background-color:$grey;
        width: 1.5em;
        height: 1.5em;
        // border-radius: 50%;
        margin-right: 0.375em;
        transition: 0.25s ease;
        box-shadow: inset 0 0 0 0.125em $vanilla;
      }
    }
  }
}
