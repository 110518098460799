@import "./../../Base.main.scss";

.main-div {
  max-width: 1300px;
  padding: 0 15px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  font-family: $font-1;

  .multi-select-container {
    margin: 10px 0;
    width: 90%;
    // width: 100%;
    box-sizing: border-box;
    max-width: 800px;

    label {
      color: $navy;
      font-size: 18px;
    }
  }

  .trainer-dropdown-container{
    margin: 10px 0;
    width: 90%;
    box-sizing: border-box;
    max-width: 800px;
    max-height: 85px;

    label {
      color: $navy;
      font-size: 18px;
    }
  }

  .input-container {
    margin: 10px 0;
    width: 90%;
    box-sizing: border-box;
    max-width: 800px;
    max-height: 85px;

    @media (max-width: 592px) {
      max-height: 64px;
    }

    label {
      color: $navy;
      font-size: 18px;
    }

    span{
      color: $navy;
    }

    input {
      padding: 0 15px;
      margin: auto;
      padding: 20px;
      width: 100%;
      border-radius: 0;
      border: 2px solid $grey;
      color: $navy;
      font-size: 16px;
      box-sizing: border-box;
      font-family: $font-1;

      @media (max-width: 592px) {
        padding: 10px;
      }

      &:focus {
        outline: none !important;
        border: 2px solid $navy;
      }
    }

    .dropdown {
      list-style-type: none;
      padding-left: 0;
      margin: 0;
      //   border: 2px solid $grey;
      position: relative;
      z-index: 2;
      background: white;

      .item {
        color: $navy;
        padding: 10px 20px;
        cursor: pointer;
        border-left: 2px solid $grey;
        border-right: 2px solid $grey;

        &:last-of-type {
          border-bottom: 2px solid $grey;
        }

        &:hover {
          background: $vanilla;
        }

        .li-flex {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .rating {
            font-size: 20px;
            position: relative;
            display: inline-block;
            transform: rotate(45deg);
          }
          
          .stars {
            .star {
              font-size: 20px;
              position: relative;
              display: inline-block;
              transform: rotate(45deg);

              @media (max-width: 592px) {
                font-size: 18px;
              }
            }
            .star-under {
              color: $grey;
              vertical-align: top;
            }
            .star-over {
              color: $navy;
              position: absolute;
              left: 0;
              top: 0;
              // width: 70%;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .textarea-container {
    margin: 20px 0;
    max-width: 800px;
    box-sizing: border-box;
    position: relative;
    width: 90%;

    label {
      color: $navy;
      font-size: 18px;
    }

    textarea {
      //   border: none;
      font-size: 18px;
      outline: 0;
      padding: 10px;
      min-height: 200px;
      width: 100%;
      font-family: $font-1;
      border: 2px solid $grey;
      box-sizing: border-box;
      color: $navy;
      resize: none;
    }
    ::placeholder {
      font-family: $font-1;
    }
  }

  .stars-div {
    margin: 10px 0;
    max-width: 800px;
    box-sizing: border-box;
    // position: relative;
    width: 90%;

    p {
      font-size: 18px;
      color: $navy;
    }
  }

  .radio-button-main {
    margin: 10px 0;
    max-width: 800px;
    box-sizing: border-box;
    // position: relative;
    width: 90%;

    span {
      font-size: 18px;
      color: $navy;
      margin: 10px 0;
    }

    .radio-button-container {
      display: flex;
      flex-direction: row;
    }
  }

  .submit-button-div {
    margin: 20px 0;
    max-width: 800px;
    box-sizing: border-box;
    // position: relative;
    width: 90%;

    button {
      width: 100%;
      background: $navy;
      color: $vanilla;
      border: none;
      padding: 15px;
      box-sizing: border-box;
      font-family: $font-1;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      cursor: pointer;
      transition: 0.3s all;

      &:hover {
        opacity: 0.7;
      }

      &:disabled {
        opacity: 0.7;
        cursor: auto;
      }
    }
  }

  .add-entry{
    width: 90%;
    box-sizing: border-box;
    max-width: 800px;
    color: $navy;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .error-span{
    width: 90%;
    box-sizing: border-box;
    max-width: 800px;
    color: $red;
    margin-bottom: 10px;
  }


.error{
  border-color: $red !important;
}
}