@import "./../../Base.main.scss";

.container{
    margin: auto;
    max-width: 800px;
    padding: 20px;
    font-family: $font-1;
    color: $navy;

    h1{
        text-align: center;
    }
}