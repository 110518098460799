@import "./../../Base.main.scss";

.li-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $navy;

  h3{
      font-size: 26px;
  }

  .name {
    font-weight: 600;
    font-size: 24px;
  }

  .rating {
    font-size: 24px;
    position: relative;
    display: inline-block;
    transform: rotate(45deg);
  }

  .rating-number {
    font-size: 24px;
    font-weight: 500;
  }
}
