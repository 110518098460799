@import "./../../Base.main.scss";

.container {
    border: 2px solid $navy;
    max-width: 800px;
    padding: 20px;
    margin: 20px auto;
    box-shadow: 0 0 2px 0 $navy;
    box-sizing: border-box;
    font-family: $font-1;
    color: $navy;

    .flex{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h2{
            margin: 0;
        }
    }


  .btn {
    // min-width: 400px;
    width: 200px;
    // max-width: 600px;
    color: $vanilla;
    font-size: 16px;
    background: $navy;
    padding: 8px 10px;
    margin: 10px 0;
    // height: 40px;
    transition: 0.3s all;
    text-align: center;
    font-family: $font-1;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    border: none;
    box-sizing: border-box;

    &:hover {
      opacity: 0.7;
    }
  }
}
