@import "./../../Base.main.scss";

.account-main {
  max-width: 1300px;
  margin: auto;
  box-sizing: border-box;
  padding: 20px;
  font-family: $font-1;

  .top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $navy;

    span {

      &:first-of-type{
        background: $navy;
        margin-right: 8px;
      }

      &:nth-child(3n){
        background: $red;
        margin-left: 8px;
      }
      background: $grey;
      padding: 8px 10px;
      font-weight: 600;
      color: $vanilla;
      cursor: pointer;
      transition: 0.3s all;
      box-sizing: border-box;

      &:hover{
          opacity: 0.7;
      }
    }
  }

  .tabs-bar {
    // border-bottom: 2px solid $navy;

    span {
      &:first-of-type {
        margin-left: 0;
      }
      margin: 0 10px;
      padding: 0 8px;
      // border-bottom: 1px solid $navy;
      cursor: pointer;
      transition: 0.3s all;
      color: $navy;
    }

    .active {
      border-bottom: 3px solid $navy;
      font-weight: 600;
    }
  }
}
