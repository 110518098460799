@import "./../../Base.main.scss";

.home {
  width: 100%;

  .home-main {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: calc(100vh - 104px);
    font-family: $font-1;

    @media (max-width: 592px) {
      flex-flow: column;
      height: calc(50vh - 80px);
    }

    .home-image {
      // margin-top: -104px;
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: cover;
      background-image: linear-gradient(
          to bottom,
          rgba(141, 153, 174, 0.8),
          rgba(141, 153, 174, 0.8)
        ),
        url("./../../../public/pexels-tread.jpg");

      .content-middle {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: $font-1;
        width: 100%;
        max-width: 1000px;

        @media (max-width: 592px) {
          display: none;
        }

        h2 {
          position: relative;
          text-transform: uppercase;
          color: $vanilla;
          font-size: 60px;
          margin-bottom: 5px;
          margin: auto;
          text-align: center;
        }

        .caption {
          display: block;
          color: $vanilla;
          font-size: 20px;
          font-weight: 500;
          text-align: center;
        }

        .all {
          // display: flex;
          display: table;
          margin: 0 auto;
          // flex-direction: row;
          // width: 100%;
          box-sizing: border-box;
          // align-items: center;
          // justify-content: center;
          padding: 10px;
          font-size: 20px;
          font-family: $font-1;
          color: $vanilla;
          cursor: pointer;
          font-weight: 600;
          // text-decoration: underline;
          background-color: $navy;

          &:hover{
            opacity: 0.7;
          }
        }
      }

      .content-mobile {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: $font-1;
        width: 100%;
        max-width: 1000px;

        h2 {
          text-transform: uppercase;
          color: $vanilla;
          font-size: 40px;
          margin-bottom: 5px;
          margin-top: 0;
          text-align: center;
        }

        .caption {
          display: block;
          color: $vanilla;
          font-size: 20px;
          font-weight: 500;
          text-align: center;
        }

        @media (min-width: 592px) {
          display: none;
        }
      }
    }
  }

  .mobile-search-container {
    border-bottom: 2px solid $navy;
    padding-bottom: 10px;
    @media (min-width: 592px) {
      display: none;
    }
    .all {
      display: flex;
      flex-direction: row;
      width: 100%;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: $font-1;
      color: $navy;
      cursor: pointer;
      font-weight: 600;
      text-decoration: underline;
    }

    .all.marginBottom{
      margin-top: 19px;
    }
  }

  .filters {
    display: flex;
    flex-direction: row;
    width: 100%;
    //   max-width: 1000px;
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box;
    font-family: $font-1;

    // align-items: center;
    // justify-content: center;
    @media (max-width: 592px) {
      padding: 20px 20px 0 20px;
    }
    .filter-div {
      min-width: 50%;
      display: flex;
      color: $vanilla;
      @media (max-width: 592px) {
        color: $navy;
      }
    }
  }

  .inputs {
    display: flex;
    flex-direction: row;
    width: 100%;
    //   max-width: 1000px;
    padding: 0 20px;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0;

    @media (max-width: 592px) {
      flex-direction: column;
    }

    .input-container {
      margin-left: 10px;
      width: 50%;
      box-sizing: border-box;
      max-width: 800px;

      @media (max-width: 592px) {
        width: 100%;
        margin-bottom: 0;
        margin-top: 10px;
        margin-left: 0;
      }

      label {
        color: $navy;
        font-size: 18px;
      }

      input {
        // padding: 0 15px;
        margin: auto;
        padding: 20px;
        width: 100%;
        border-radius: 0;
        border: 2px solid $grey;
        color: $navy;
        font-size: 16px;
        box-sizing: border-box;
        font-family: $font-1;
        background: $vanilla;

        @media (max-width: 592px) {
          padding: 10px;
          height: 43px;
        }

        &::placeholder {
          color: rgb(153, 153, 153);
          opacity: 1;
        }

        &:focus {
          outline: none !important;
          border: 2px solid $navy;
        }
      }
    }
  }

  .inputs.classes {
    justify-content: flex-start;
  }
}
