@import "./../../Base.main.scss";

.trainer-profile-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: auto;
  box-sizing: border-box;

  .trainer-profile-flex {
    display: flex;
    flex-direction: row;

    @media (max-width: 1045px) {
      flex-direction: column;
    }
  }
}

.header {
  width: 40%;
  box-sizing: border-box;

  padding: 20px;

  @media (max-width: 1045px) {
    width: 100%;
    padding: 0;
    padding-bottom: 20px;
  }

//   margin: auto;
}
.reviews {
  width: 60%;
  max-width: 840px;
  margin: auto;
  // margin-top: 40px;
  padding: 20px;
  box-sizing: border-box;
  border-left: 2px solid $navy;

  @media (max-width: 1045px) {
    width: 100%;
    border-left: none;
    padding: 0;
  }

  .reviews-scroll{
    height: calc(100vh - 169px);
    overflow-y: scroll;

    @media (max-width: 1045px) {
      height: auto;
      overflow-y: auto;
    }
  }
}

.dropdown-container {
  max-width: 800px;
  // padding: 20px;
  // margin: 20px auto;
  // box-shadow: 0 0 4px 0 $navy;
  box-sizing: border-box;
  // border: 2px solid $navy;
  // font-family: $font-1;
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-left: auto;
  margin-right: 0;
  align-items: center;
}
