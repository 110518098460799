@import "./../../Base.main.scss";

.form-container {
  width: 50%;
  box-sizing: border-box;
  max-width: 800px;
  height: 82px;

  @media (max-width: 592px) {
    width: 100%;
    margin-bottom: 5px;
    height: 43px;
  }

  .input-container {
    width: 100%;
    max-height: 82px;
    // display: flex;
    align-items: center;

    @media (max-width: 592px) {
      width: 100%;
      margin-bottom: 0;
      margin-left: 0;
      // border-bottom: 1px solid $navy;
    }

    label {
      color: $navy;
      font-size: 18px;
    }

    input {
      // padding: 0 15px;
      order: 2;
      margin: auto;
      padding: 20px 45px;
      width: 100%;
      border-radius: 0;
      border: 2px solid $grey;
      color: $navy;
      font-size: 16px;
      box-sizing: border-box;
      font-family: $font-1;
      background: white;

      @media (max-width: 592px) {
        padding: 10px 45px 10px;
      }

      &::placeholder {
        color: rgb(153, 153, 153);
        opacity: 1;
      }

      &:focus {
        outline: none !important;
        border: 2px solid $navy;
      }
    }

    .reset {
      position: relative;
      font-family: $font-1;
      float: right;
      bottom: 40px;
      right: 15px;

      @media (max-width: 592px) {
        bottom: 30px;

      }

      cursor: pointer;
      color: #999999;

      &:hover {
        color: $navy;
      }
    }

    .submit {
      position: relative;
      // order: 3;
      // right: 45px;
      float: left;
      bottom: 45px;
      left: 10px;
      font-size: 20px;

      @media (max-width: 592px) {
        bottom: 35px;
      }
    }
  }
}
