@import "./../../Base.main.scss";

.container{
    // background: $grey;
    border-bottom: 2px solid $navy;
    max-width: 1000px;
    margin: auto;


    .content{
        padding: 20px;
        box-sizing: border-box;

        font-family: $font-1;
        color: $navy;
        
        h1{
            text-align: center;
        }
        
        h2{
            margin-top: 0;
        }

        p{
            font-weight: 500;
        }
    }
}