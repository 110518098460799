@import "./../../Base.main.scss";

.container {
  .content {
    max-width: 1000px;
    padding: 20px;
    box-sizing: border-box;
    margin: auto;
    font-family: $font-1;
    color: $navy;

    h1 {
      text-align: center;
    }

    p {
      font-weight: 500;
    }

    .flex {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;

      @media (max-width: 592px) {
        flex-direction: column-reverse;
      }

      .bio {
        width: 60%;

        @media (max-width: 592px) {
          width: 100%;
        }
      }

      .image {
        width: 40%;
        background-size: cover;
        background-repeat: no-repeat;
        // min-height: 350px;
        height: 250px;
        padding: 5%;
        margin: 5px;

        @media (max-width: 592px) {
          width: 90%;
        }
      }
      .image1 {
        background-position: center;
      }

      .image2 {
        background-position: top;
      }
    }
  }
}
