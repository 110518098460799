@import "./../../Base.main.scss";

.title {
  font-family: $font-1;
  color: $navy;
}

.user-null {
  position: fixed;
  top: 0;
  left: 0;
  background-color:rgba(141, 153, 174, 0.4);
  height: 100vh;
  width: 100%;
  z-index: 2;

  .user-null-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: $font-1;
    color: $navy;
    background-color: $vanilla;
    padding: 20px;
    text-align: center;
    width: calc(100vw - 40px);
    box-sizing: border-box;
    max-width: 600px;
    
    h2{
      margin-bottom: 0
    }

    p {
      margin-top: 0;
      text-align: center;
      margin-bottom: 20px;
    }

    span span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}



.modalH1 {
  margin: 0;
}

.modalP {
  margin-bottom: 0;
  text-align: center;
  margin-top: 20px;
}

.modalLogin {
  cursor: pointer;
  background-color: $navy;
  color: $vanilla;
  padding: 8px 10px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.3s all;

  &:hover {
    opacity: 0.7;
  }
}