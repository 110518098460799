@import "./../../Base.main.scss";

.google-container {
  position: relative;
  //   max-width: 800px;
  width: 100%;
  font-family: $font-1;
  box-sizing: border-box;

  input {
    padding-right: 40px !important;
  }
  .google-ul {
    // display:none;
    position: absolute;
    box-sizing: border-box;
    // left: 0;
    width: 100%;
    list-style: none;
    padding-left: 0px;
    border: solid 2px $grey;
    background: white;
    padding: 0;
    margin: 0;
    z-index: 5;
  }

  .google-option {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    cursor: pointer;
    color: $navy;
    width: 100%;
    box-sizing: border-box;

    &:hover {
      background-color: $vanilla;
    }

    .checkbox {
      margin-right: 6px;
      color: $navy;
    }
  }

  .icons {
    position: relative;
    float: right;
    right: 10px;
    bottom: 40px;

    @media (max-width: 592px) {
      bottom: 30px;
    }

    .icon {
      cursor: pointer;
      color: #999999;

      &:first-of-type {
        margin-right: 5px;
        @media (max-width: 592px) {
          margin-right: 0;
        }
      }

      &:hover {
        color: $navy;
      }
    }
  }

  .icons.header {
    bottom: 30px;
  }

  .icons.burger {
    bottom: 30px;
  }


}
