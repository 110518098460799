@import "./../../Base.main.scss";

.ais-SearchBox {
//   max-width: 1000px;
//   margin: auto;
//   padding: 20px;
  box-sizing: border-box;
  max-width: 800px;
  width: 50%;
  height: 82px;

  @media (max-width: 592px) {
    width: 100%;
    margin-bottom: 10px;
    height: 44px;
  }

  .ais-SearchBox-input {
    // padding: 0 15px;
    // margin: auto;
    padding: 20px;
    padding-left: 50px;
    width: 100%;
    border-radius: 0;
    border: 2px solid $grey;
    color: $navy;
    font-size: 16px;
    box-sizing: border-box;
    font-family: $font-1;
    caret-color: auto;
    box-shadow: none;

    &::placeholder {
      color: rgb(153, 153, 153);
    }

    @media (max-width: 592px) {
      padding: 10px;
      padding-left: 30px;
    }

    &:focus {
      outline: none !important;
      border: 2px solid $navy;
    }

    // outline: $navy;
  }

  .ais-SearchBox-form::before {
    display: none;
    // left: 0;
    // top: 0;
    // margin-top: 0;
    // height: 100%;
    // width: 80px;
    // background: $navy;
    // content: "Search";
    // color: $vanilla;
  }

  .ais-SearchBox-form {
    height: auto;
  }
}
.ais-Hits {
//   max-width: 1000px;
//   margin: auto;
  padding: 20px;
  box-sizing: border-box;




  .hits-grid{
    display: grid;
    grid-template-columns: repeat(1,minmax(0,1fr));
    column-gap: 2px;

  
  }

  .ais-Hits-item{
    color: $navy;
    &:nth-of-type(2n){
      background: $navy;
      color: $vanilla;
    }
      padding: 0px;
      box-sizing: border-box;
      border-radius: 0;
      background: none;
    //   box-shadow: 0 0 4px 0 $navy;
      border: 1px solid $navy;
      transition: 0.3s all;
      align-items: flex-start;
      justify-content: center;
      cursor: pointer;
      // max-width: 500px;

      &:hover{
        opacity: 0.5;

      }


}
}

.ais-SearchBox-submit {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  outline: none;
  order: 1;
  font-size: 24px;
  color: $navy;

  @media (max-width: 592px) {
    top: 15px;
    left: 10px;
    font-size: 16px;
  }
}

.ais-SearchBox-submitIcon {
  font-weight: 600;
  //   background-color: $grey;
}

.ais-RefinementList-count {
  background-color: $navy;
  border-radius: 0;
  color: $vanilla;
  border: none;
}

.ais-RefinementList-labelText {
  font-family: $font-1;
  color: $navy;
}

.ais-RefinementList-checkbox {
    color: $navy;
    box-shadow: none;

}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox{
    background-image: none;
    color: $navy;
    appearance: auto;
}

.ais-Pagination-list{
  justify-content: center;
}

.ais-Pagination-item:first-child > .ais-Pagination-link, .ais-Pagination-item:last-child > .ais-Pagination-link {
  border-radius: 0;
  background: white;
  color: $navy;
}

.ais-Pagination-item + .ais-Pagination-item > .ais-Pagination-link{
  background: white;
  color: $navy;
}

.ais-Pagination-item--selected .ais-Pagination-link{

 background: $navy !important;
 color: white !important;
 box-sizing: border-box !important;
 border-color: #efeff5 !important;
 box-shadow: none !important;
}