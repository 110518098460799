
// $font-1: "Archivo Narrow", sans-serif;
// $font-1: 'Asap', sans-serif;
// $font-1: 'Fira Sans Extra Condensed', sans-serif;
$font-1: "League Spartan", sans-serif;
// $font-1: "Black Ops One", sans-serif;
// $font-1: "Arimo", sans-serif;
// $font-2: "Optima", sans-serif;

$vanilla: #EDF2F4;
$grey: #8D99AE;
$navy: #2B2D42;
$red: #EF233C;