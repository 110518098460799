@import "./../../Base.main.scss";

.account-main {
  max-width: 1300px;
  margin: auto;
  box-sizing: border-box;
//   padding: 20px;
  font-family: $font-1;


  .flex{
      display: flex;
      flex-direction: row;
      justify-content: center;

      @media (max-width: 1050px){
        flex-direction: column;
        align-items: center;
      }


      .flex-div{
          width: calc(100%/3);
          padding: 10px;

          @media (max-width: 1050px){
            width: 100%;
            border: none;
          }
    

          &:first-child{
              border: none;
          }

          border-left: 1px solid $navy;

          
      }
  }
}
