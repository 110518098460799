@import "./../../Base.main.scss";

.container {
  max-width: 1200px;
  font-family: $font-1;
  margin: auto;
  box-sizing: border-box;

  @media (min-width: 592px) and (max-width: 1045px) {
    max-width: 840px;
  }

  hr {
    width: 90%;
    border: 1px solid $navy;
  }
  .flex {
    display: flex;
    // flex-direction: row;
    flex-direction: column;

    // @media (max-width: 890px) {
    //   flex-direction: column;
    // }

    .left {
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      box-sizing: border-box;

      width: 100%;
      padding: 20px 0px 0px 0px;

      @media (min-width: 592px) and (max-width: 1045px) {
        width: 50%;
      }

      .name {
        margin: 0;
        color: $navy;
        font-size: 48px;
      }

      .city {
        color: $navy;
        font-size: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .tags {
        font-size: 20px;
        display: flex;
        color: $navy;
      }

      .types {
        display: flex;
        color: $navy;

        .type {
          padding: 5px 10px;
          margin: 0 10px;
          box-shadow: 0 0 4px 0 $navy;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      // width: 50%;
      box-sizing: border-box;
      // padding: 40px 60px;
      width: 100%;
      padding: 20px 0px 0px 0px;

      @media (min-width: 592px) and (max-width: 1045px) {
        width: 50%;
      }

      .stars {
        .star {
          font-size: 50px;
          position: relative;
          display: inline-block;
          transform: rotate(45deg);

          @media (max-width: 592px) {
            font-size: 40px;
          }
        }
        .star-under {
          color: $grey;
          vertical-align: top;
        }
        .star-over {
          color: $navy;
          position: absolute;
          left: 0;
          top: 0;
          // width: 70%;
          overflow: hidden;
        }
      }

      .reviews {
        font-size: 20px;
        font-weight: 500;
        color: $navy;
        margin: 5px 0;
      }

      .again {
        color: $navy;
      }

      .add-review-button {
        background: $navy;
        padding: 8px 10px;
        font-weight: 600;
        color: $vanilla;
        cursor: pointer;
        transition: 0.3s all;
        box-sizing: border-box;
        display: block;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
