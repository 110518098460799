@import "./../../Base.main.scss";

.aa-Autocomplete {
  height: 82px;
  width: 50%;
  font-family: $font-1;
  position: relative;
  // width: 100%;

  @media (max-width: 592px) {
    width: 100%;
    height: auto;
  }
}

.aa-Autocomplete.burger{
  width: 100%;
  height: auto;
}

.aa-Autocomplete.header {
  height: auto;
}

.aa-Autocomplete.form {
  width: 100%;
}


.aa-Form {
  border-radius: 0;
  height: 58.5px;
  border: 2px solid #8d99ae;
  box-sizing: border-box;

  @media (max-width: 592px) {
    height: 43px;
  }
}

.aa-Form.burger{
  height: 38.5px;
}

.aa-Form.header {
  height: 38.5px;
}

.aa-Input {
  margin: auto;
  padding: 20px;
  width: 100%;
  border-radius: 0;
  color: #2b2d42;
  font-size: 16px;
  box-sizing: border-box;
  font-family: $font-1;

  @media (max-width: 592px) {
    padding: 10px;
  }
  /* background: #EDF2F4; */
}

.aa-Input.burger{
  padding: 10px;
}

.aa-Input.header {
  padding: 10px;
}

.aa-Panel {
  width: 100%;
  list-style: none;
  padding-left: 0px;
  border: solid 2px $grey;
  background: white;
  padding: 0;
  margin: 0;
  z-index: 5;
  position: absolute;
  border-radius: 0;
  max-height: 200px;
  overflow-y: scroll;
}

.aa-ClearButton {
  font-family: $font-1;
  font-size: 16px;
  padding: 0 10px;
}

// .aa-List{
// }

.aa-Item {
  padding: 10px;
}
:root {
  --aa-primary-color-rgb: 43, 45, 66;
  --aa-primary-color-alpha: 0;
  --aa-input-background-color-rgb: 237, 242, 244;
  --aa-muted-color-rgb: 153, 153, 153;
  --aa-muted-color-alpha: 1;
  --aa-selected-color-rgb: 237, 242, 244;
  --aa-selected-color-alpha: 1;
}
