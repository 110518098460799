@import "./../../Base.main.scss";

.review {
  border: 2px solid $navy;
  max-width: 800px;
  // padding: 20px;
  margin: 20px auto;
  box-shadow: 0 0 2px 0 $navy;
  box-sizing: border-box;
  font-family: $font-1;

  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 20px;
    &:first-of-type {
      background: $navy;
    }

    box-sizing: border-box;

    @media (max-width: 592px) {
      // flex-direction: column;
      // align-items: flex-start;
      padding: 5px 10px;
    }

    .location-flex {
      display: flex;
      flex-direction: row;
      .data {
        &:first-of-type {
          // background: $grey;
          // color: $vanilla;
          margin-right: 10px;
        }
        // background: $navy;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        color: $vanilla;
        // padding: 5px;
        box-sizing: border-box;
        @media (max-width: 592px) {
          flex-direction: column;
        }
        .name {
          font-size: 18px;
          font-weight: 500;
          margin-left: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
        .gym-name {
          font-size: 18px;
          font-weight: 500;
          margin-left: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        // color: $nav;

        // flex: 1 1;
      }
    }

    // .name {
    //   font-size: 20px;
    //   font-weight: 500;
    //   color: $navy;
    // }

    .stars {
      // display: none;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      color: $vanilla;
      // background: $vanilla;

      @media (max-width: 592px) {
        margin-top: 5px;
        display: none;
      }
      .star {
        font-size: 24px;
        position: relative;
        display: inline-block;
        transform: rotate(45deg);
      }
      .star-under {
        font-size: 24px;
        color: $grey;
        vertical-align: top;
      }
      .star-over {
        color: $vanilla;
        position: absolute;
        left: 0;
        top: 0;
        // width: 70%;
        overflow: hidden;
      }
    }
  }

  .stars-mobile {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    color: $vanilla;

    @media (min-width: 592px) {
      display: none;
    }
    .star {
      font-size: 18px;
      position: relative;
      display: inline-block;
      transform: rotate(45deg);
    }

    .rating {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .content {
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid $navy;

    @media (max-width: 592px) {
      padding: 10px;
    }

    .comments {
      font-size: 18px;
      color: $navy;
      margin: 0px 0;
    }

    .bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: $navy;
      margin-top: 5px;

      .like {
        cursor: pointer;
        .red {
          color: $red;
          // font-size: 30px;
        }
      }
    }
  }
}

.location {
  padding: 5px 0;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info {
  span {
    color: $navy;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.modalH1 {
  margin: 0;
}

.modalP {
  margin-top: 0;
  text-align: center;
  margin-bottom: 20px;
}

.modalLogin {
  cursor: pointer;
  background-color: $navy;
  color: $vanilla;
  padding: 8px 10px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.3s all;

  &:hover {
    opacity: 0.7;
  }
}

.modalSignUp {
  cursor: pointer;
  border: 2px solid $navy;
  background-color: $navy;
  color: $vanilla;
  padding: 8px 10px;
  text-transform: uppercase;
  margin-left: 10px;
}

.tags {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin: 10px 0;
  .tag {
    padding: 5px;
    box-shadow: 0 0 2px 0 $navy;
    margin-right: 5px;
  }
}
