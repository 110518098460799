@import "./../../Base.main.scss";

.main-div {
  max-width: 1300px;
  padding: 0 15px;
  max-width: 1300px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  font-family: $font-1;
  h2 {
    font-family: $font-1;
    color: $navy;
    text-transform: uppercase;
    font-size: 32px;
    text-align: center;
    margin-bottom: 0;
  }
  a {
    color: black;
    text-decoration: underline;
    cursor: pointer;
  }

  .reset-span{
    color: $navy;
  }

  .login-btn {
    // min-width: 400px;
    width: 100%;
    max-width: 600px;   
    color: $vanilla;
    font-size: 16px;
    background: $navy;
    padding: 8px 10px;
    margin: 10px 0;
    height: 40px;
    transition: 0.3s all;
    text-align: center;
    font-family: $font-1;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    border: none;
    box-sizing: border-box;

    &:hover {
      opacity: 0.7;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .login-btn.delete{
    background: $red;
  }

  .email,
  .password {
    margin: 5px 0;
    // min-width: 400px;
    max-width: 600px;
    box-sizing: border-box;
    height: 63px;
    position: relative;
    width: 100%;

    input {
      padding: 20px;
      width: 100%;
      border-radius: 0;
      border: 2px solid $grey;
      color: $navy;
      font-size: 16px;
      box-sizing: border-box;
      font-family: $font-1;

      &:focus {
        outline: none !important;
        border: 2px solid $navy;
      }


    }
    input.error {
      color: $red;
      border-color: $red;
    }
  }
  .eye {
    position: relative;
    float: right;
    right: 10px;
    bottom: 40px;
    cursor: pointer;
    color: $navy;
  }
}

.error-message {
  margin: 0px 0;
  // min-width: 400px;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  position: relative;
  color: $red;
  height: 19px;
}
