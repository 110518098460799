@import "./../../Base.main.scss";

.header-main {
  position: sticky;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 3;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 1s;
  background: $navy;
  // box-shadow: 0px 5px 2px -2px rgb(0 0 0 / 15%);
  // border-bottom: 6px solid $grey;
  // border-top: 6px solid $grey;
}

.white {
  // box-shadow: 0px 5px 2px -2px rgb(0 0 0 / 15%);
  // background: rgba(246, 243, 237, 0.75);
  // background: $navy;
}
.nav-menu {
  float: left;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1000px;
  margin-left: 10px;
  box-sizing: border-box;
  // margin-top: 12px;

  .search {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 100%;
    box-sizing: border-box;

    .filters {
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 1000px;
      padding-bottom: 0;
      box-sizing: border-box;
      font-family: $font-1;

      .filter-div {
        width: 50%;
        display: flex;
        color: $vanilla;
        box-sizing: border-box;

        &:first-of-type {
          margin-right: 10px;
        }
      }
    }

    .inputs {
      display: flex;
      flex-direction: row;
      width: 100%;
      //   max-width: 1000px;
      min-width: 500px;
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: center;
      padding-top: 0;

      .input-container {
        // margin: 10px;
        width: 50%;
        box-sizing: border-box;
        max-width: 800px;
        height: 43px;
        margin-left: 10px;

        label {
          color: $navy;
          font-size: 18px;
        }

        input {
          // padding: 0 15px;
          margin: auto;
          padding: 10px;
          width: 100%;
          border-radius: 0;
          border: 2px solid $grey;
          color: $navy;
          font-size: 16px;
          box-sizing: border-box;
          font-family: $font-1;
          background: $vanilla;

          &::placeholder {
            color: rgb(153, 153, 153);
            opacity: 1;
          }

          &:focus {
            outline: none !important;
            border: 2px solid $navy;
          }
        }
      }
    }
    .inputs.classes {
      justify-content: flex-start;
    }
  }
}

.nav-item {
  float: left;
  position: relative;
  margin-left: 40px;
  font-size: 20px;
}

.pop-nav-item {
  position: relative;
  padding-left: 10px;

  &:hover {
    background-color: $grey;

    a {
      color: $navy;
    }
  }

  a {
    display: block;
    line-height: 36px;
    font-size: 20px;
    color: $navy;
    -webkit-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    cursor: pointer;
    font-family: $font-1;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
  }
}

.header-second-level-desktop {
  width: 92%;
  max-width: 1300px;
  margin: auto;
  padding: 10px 0;
  height: 66px;

  @media (max-width: 1090px) {
    display: none;
  }
}

.header-second-level-mobile {
  width: 92%;
  max-width: 1300px;
  margin: auto;
  padding: 10px 0;
  height: 44px;

  @media (min-width: 1091px) {
    display: none;
  }
}

.header-second-level-desktop .logo {
  img {
    height: 66px;
    display: block;
    float: left;
    margin-top: 0px;
  }
}

.header-second-level-mobile .logo {
  img {
    height: 44px;
    display: block;
    float: left;
    margin-top: 0px;
    // transform: translate(-50%, -50%);
    // margin-left: 5px;
    // transform: translate(-50%);
  }
}

.header-right {
  float: right;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;

  @media (max-width: 1090px) {
    // display: none;
    margin-top: 0;
  }
}

.nav-item > span,
.nav-item > a {
  display: block;
  // line-height: 36px;
  font-size: 20px;
  color: $grey;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
  cursor: pointer;
  font-family: $font-1;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
}

.login {
  color: $grey;
  display: block;
  line-height: 36px;
  float: left;
  font-family: $font-1;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
}

.try-btn {
  color: $navy;
  font-size: 16px;
  display: block;
  line-height: 36px;
  float: left;
  background: $grey;
  // width: 143px;
  padding: 0 15px;
  height: 100%;
  margin-left: 5px;
  // border-radius: 10px;
  text-decoration: none;
  transition: 0.3s all;
  text-align: center;
  font-family: $font-1;
  text-transform: uppercase;
  font-weight: 600;

  // margin-top: 12px;

  @media (max-width: 1090px) {
    width: auto;
    padding: 0 15px;
    line-height: 44px;
  }
}

.try-btn:hover {
  opacity: 0.7;
}

.profile-icon {
  background: $grey;
  color: $navy !important;
  height: 30px;
  width: 30px;
  // box-sizing: border-box;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px solid $grey;
  &:hover {
    // border: 3px solid $vanilla;
    opacity: 0.7;
  }
}
