@import "./../../Base.main.scss";

.container{
    // background: $grey;
    border-bottom: 2px solid $navy;
    max-width: 1000px;
    margin: auto;

    .content{
        max-width: 1000px;
        padding: 20px;
        box-sizing: border-box;
        margin: auto;
        font-family: $font-1;
        color: $navy;

        h1{
            text-align: center;
            text-transform: uppercase;
        }

        p{
            font-weight: 500;
        }
    }
}